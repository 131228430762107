import React from 'react'
import { Link } from 'gatsby'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Hi, my name is Jonathan.</h1>
            </header>
            <div className="content">
                <p>Digital Innovation and Emerging Technology Strategist</p>
                <Link title="Jonathan Agoot - About me" to="/about-me" className="button special">
                    About Me
                </Link>
            </div>
        </div>
    </section>
)

export default Banner
